import { Box, Stack, Typography, Grid, Avatar, Button, Autocomplete, TextField, styled } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useIntl } from 'react-intl';
import AutocompleteWithError from 'components/Forms/FormAutoComplete';
import { IconPlus, IconSearch, IconCircleMinus, IconCirclePlus } from '@tabler/icons-react';
import PropTypes from 'prop-types';
import InviteesLoading from 'components/@skeletons/InviteesLoading';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import messages from '../../pages/InitiativeHub/Add/messages';
import AvatarNameWithButton from './AvatarNameWithButton';
import { useLazyGetSearchedUserQuery } from 'store/rtk-query/usersListApi';
import useDebounce from 'hooks/useDebouncedSearch';
import { handleSearch } from 'utils/initiativesList';
import {
  useAddInviteeMutation,
  useAddOrganizationAuthorityMutation,
  useDeleteInitiativeInviteeMutation,
  useDeleteOrganizationAuthorityMutation,
  useGetInitiativeInviteeQuery,
  useGetOrganizationAuthorityQuery
} from 'store/rtk-query/inviteeApi';
import { colors } from 'utils/constants/colors';
import { imagePathResolver } from 'utils/imageResolvers';
import { getCapitalizeFirst } from 'utils/getCapitalize';
import { filterInviteesOptions } from 'utils/utils';
import { networkTypeObj } from 'utils/constants/globalConstant';
import paths from 'utils/constants/paths';

const InviteeData = ({ initiativeId }) => {
  const { data: getInviteesResult, isLoading: getInviteesIsLoading, refetch } = useGetInitiativeInviteeQuery({ initiativeId });
  const { data: getOrgAuthorityResult, isLoading: getOrgAuthorityIsLoading } = useGetOrganizationAuthorityQuery({ initiativeId });
  const modifiedData = getInviteesResult && getOrgAuthorityResult && [...getInviteesResult, ...getOrgAuthorityResult];
  return { getInviteesResult: modifiedData, getInviteesIsLoading: getInviteesIsLoading && getOrgAuthorityIsLoading, refetch };
};

const filterOptionsHelper = (options, getInviteesArray, administrators, isUpdate, administratorsList, members) => {
  return options.filter((item) => {
    const isInvited = getInviteesArray?.some((x) => x.uuid === item.uuid);
    const isAdmin = administrators?.some((x) => x.uuid === item.uuid);
    const isMember = members?.some((x) => x.uuid === item.uuid);
    const currentAdministrator = isUpdate ? administratorsList?.some((x) => x.uuid === item.uuid) : false;
    return !isInvited && !isAdmin && !currentAdministrator && !isMember;
  });
};

export const StyledTitle = styled(Typography)(({ theme, hideBorder }) => ({
  color: theme.palette.common.black,
  borderBottom: hideBorder ? `1px solid ${theme.palette.grey[300]}` : `1px solid ${theme.palette.primary.mainLight}`,
  fontWeight: 550,
  paddingBottom: theme.spacing(1)
}));

export const StyledGreyContainer = styled(Box)(({ theme, hideBorder }) => ({
  backgroundColor: theme.palette.secondary.A400,
  borderRight: hideBorder ? '' : `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(2, 0, 2, 4),
  position: 'sticky',
  top: 90,
  alignSelf: 'flex-start'
}));

const InviteOrAddNetwork = ({
  initiativeId = '',
  multipleInviteeList = [],
  setMultipleInviteeList = () => {},
  userId,
  administrators,
  administratorsList,
  setInviteesList = () => {},
  members,
  hideBorder,
  showRecommendation = false,
  recommendationUsersList = [],
  recommendationOrganizationList = []
}) => {
  const debouncedSearch = useDebounce(handleSearch, 500);
  const [deleteInitiativeInvitee] = useDeleteInitiativeInviteeMutation();
  const [deleteOrganizationAuthority] = useDeleteOrganizationAuthorityMutation();
  const { palette } = useTheme();
  const [value, setValue] = useState('');
  const intl = useIntl();
  const navigate = useNavigate();
  const [getSearchedUser, { data, isLoading }] = useLazyGetSearchedUserQuery();
  const { getInviteesResult, getInviteesIsLoading } = initiativeId
    ? InviteeData({ initiativeId })
    : { getInviteesResult: [], getInviteesIsLoading: false, refetch: () => {} };

  const [addInvitee] = useAddInviteeMutation();
  const [addOrganizationAuthority] = useAddOrganizationAuthorityMutation();
  const handleInputChange = (e) => {
    const searchValue = e?.target?.value || '';
    const searchParams = { search: searchValue };
    debouncedSearch(getSearchedUser, searchParams);
  };

  useEffect(() => {
    if (!getInviteesResult?.length) {
      setInviteesList(getInviteesResult);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modifiedOptions =
    data?.map(({ firstName, lastName, profileImage, ...rest }) => ({
      name: `${firstName} ${lastName}`,
      avatarUrl: imagePathResolver(profileImage),
      ...rest
    })) || [];

  const CustomRenderOption = (props, option) => {
    initiativeId && delete props.onClick;
    const handleAddOption = async (e, invitee) => {
      setValue({ name: '', firstName: '', title: '' });
      if (invitee.networkType === networkTypeObj.PERSONS) {
        const response = await addInvitee({ initiativeId, inviteeUserId: invitee.uuid });
        if (response?.data) toast.success(`Invitation send to user's mail successfully`);
      } else {
        const response = await addOrganizationAuthority({ initiativeId, inviteeUserId: invitee.uuid });
        if (response?.data) toast.success(`Invitation send to user's mail successfully`);
      }
    };

    return (
      <Box
        {...props}
        sx={(theme) => ({
          cursor: 'alias !important',
          display: 'flex',
          alignItems: 'center',
          padding: '4px 12px',
          borderBottom: `1px solid ${theme.palette.primary.light}`,
          borderRight: `1px solid ${theme.palette.primary.light}`,
          borderLeft: `1px solid ${theme.palette.primary.light}`,
          '&:first-of-type': {
            borderTop: `1px solid ${theme.palette.primary.light}`
          },
          '&:last-child': {
            borderBottom: `1px solid ${theme.palette.primary.light}`
          }
        })}
      >
        <Avatar
          alt={option.name}
          src={option.avatarUrl}
          // eslint-disable-next-line react/no-children-prop
          children={getCapitalizeFirst(option.name)}
        />
        <Typography variant="body1" style={{ marginLeft: '8px' }}>
          {option.name}
        </Typography>
        <Button
          sx={(theme) => ({
            borderRadius: theme.spacing(1),
            height: '1.5rem',
            border: `1px solid ${theme.palette.primary.main}`,
            ':hover': {
              border: `1px solid ${theme.palette.grey[800]}`,
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.secondary.cardBackground
            }
          })}
          endIcon={<IconPlus size="16px" />}
          variant="contained"
          color="primary"
          onClick={(e) => initiativeId && handleAddOption(e, option)}
          style={{ marginLeft: 'auto' }}
        >
          Add
        </Button>
      </Box>
    );
  };

  const handleDeleteInvitee = async (id, isOrganization) => {
    const result = (await isOrganization)
      ? deleteOrganizationAuthority({ initiativeId, inviteeUserId: id })
      : deleteInitiativeInvitee({ initiativeId, inviteeUserId: id });
    if (result.data) {
      toast.success(result.data);
    }
  };

  const handleRemovalAddInitiativeList = (id) => {
    const filteredInvitees = multipleInviteeList.filter((item) => item.uuid !== id);
    setMultipleInviteeList(filteredInvitees);
  };

  const handleAddRecommendedUsers = async (id) => {
    await addInvitee({ initiativeId, inviteeUserId: id });
  };
  const handleAddRecommendedOrganization = async (id) => {
    await addOrganizationAuthority({ initiativeId, inviteeUserId: id });
  };

  const handleTitleClick = (networkId, isOrganization) => {
    navigate(`${paths.Network.url}/${networkId}/${isOrganization ? networkTypeObj.ENTERPRISES : networkTypeObj.PERSONS}`);
  };

  return (
    <StyledGreyContainer hideBorder={hideBorder}>
      <StyledTitle hideBorder={hideBorder}>{intl.formatMessage(messages.InviteNetwork)}</StyledTitle>
      <Box>
        <Stack>
          {(!!multipleInviteeList?.length || !!getInviteesResult?.length) && (
            <Typography pt={1.5} pb={0.5} variant="body2" color={colors.dark[550]}>
              {intl.formatMessage(messages.requestSent)}
            </Typography>
          )}
          {getInviteesIsLoading && <InviteesLoading />}
          {!initiativeId ? (
            <Grid container spacing={1} justifyContent={'flex-start'} pt={1} pb={2}>
              {multipleInviteeList.length
                ? multipleInviteeList?.map(({ name, uuid, avatarUrl }) => (
                    <AvatarNameWithButton
                      key={uuid}
                      avatarUrl={avatarUrl}
                      id={uuid}
                      name={name}
                      buttonIcon={<IconCircleMinus />}
                      onClick={!initiativeId ? handleRemovalAddInitiativeList : handleDeleteInvitee}
                      isOrganization={!!name}
                    />
                  ))
                : null}
            </Grid>
          ) : (
            <Grid container spacing={1} justifyContent={'flex-start'} pt={1} pb={2}>
              {getInviteesResult?.length
                ? getInviteesResult?.map(({ uuid, firstName, lastName, name, profileImage, academicTitles }) => (
                    <AvatarNameWithButton
                      key={uuid}
                      id={uuid}
                      name={name ? name : `${academicTitles || ''} ${firstName} ${lastName}`}
                      buttonIcon={<IconCircleMinus />}
                      size={30}
                      avatarUrl={imagePathResolver(profileImage)}
                      onClick={!initiativeId ? handleRemovalAddInitiativeList : handleDeleteInvitee}
                      isOrganization={!!name}
                      handleTitleClick={() => handleTitleClick(uuid, !!name)}
                    />
                  ))
                : null}
            </Grid>
          )}
        </Stack>
      </Box>
      <Stack width={'90%'}>
        {initiativeId ? (
          <AutocompleteWithError
            onFocus={() => getSearchedUser({ search: '' })}
            label={intl.formatMessage(messages.addPeopleLabel)}
            placeholder={isLoading ? 'Loading users ' : intl.formatMessage(messages.addPeoplePlaceholder)}
            options={filterOptionsHelper(modifiedOptions, getInviteesResult, administrators, initiativeId, administratorsList, members)}
            customRenderOption={(props, option) => CustomRenderOption(props, option)}
            hideDropDownIcon
            filterSelectedOptions={false}
            inputBackground="white"
            showRenderTags
            onInputChange={handleInputChange}
            value={value}
          />
        ) : (
          <Autocomplete
            onFocus={() => getSearchedUser({ search: '' })}
            multiple
            options={filterInviteesOptions(modifiedOptions, multipleInviteeList, administratorsList, userId)}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            onChange={(_, newValue) => {
              setMultipleInviteeList(newValue);
            }}
            value={multipleInviteeList}
            onInputChange={handleInputChange}
            popupIcon={<IconSearch color={palette.primary.main} />}
            renderOption={(props, option) => CustomRenderOption(props, option)}
            renderInput={(params) => (
              <TextField
                InputProps={{ spellCheck: true }}
                focused
                {...params}
                placeholder={isLoading ? 'Loading users ' : intl.formatMessage(messages.addPeoplePlaceholder)}
              />
            )}
            renderTags={() => null}
            sx={(theme) => ({
              '& .MuiInputLabel-root': {
                color: 'black',
                fontSize: '16px',
                [theme.breakpoints.down('lmmd')]: {
                  fontSize: '14px'
                },
                background: `${palette.secondary.A400}`
              },
              '& .MuiSvgIcon-root': {
                display: 'none'
              },
              '& .MuiAutocomplete-inputRoot': {
                paddingRight: '4px !important',
                background: `${theme.palette.primary.contrastText} !important`
              },
              '& .MuiButtonBase-root': {
                transform: 'rotate(0deg)'
              }
            })}
          />
        )}
      </Stack>
      <Stack>
        {showRecommendation && (
          <>
            {recommendationUsersList.length ? (
              <>
                <Typography pt={1.5} pb={0.5} variant="body2" color={colors.dark[550]}>
                  {intl.formatMessage(messages.recommendedUserTitle)}
                </Typography>
                <Grid container spacing={1} justifyContent={'flex-start'} pt={1} pb={2}>
                  {recommendationUsersList?.map(({ name, uuid, avatarUrl, matchingPercentage, matchedKeywords }) => (
                    <AvatarNameWithButton
                      key={uuid}
                      avatarUrl={avatarUrl}
                      id={uuid}
                      name={name}
                      label={Number(matchingPercentage).toFixed(2)}
                      buttonIcon={<IconCirclePlus />}
                      iconColor={colors.default}
                      onClick={handleAddRecommendedUsers}
                      handleTitleClick={() => handleTitleClick(uuid, false)}
                      toolTipTitle={matchedKeywords.map((title) => `${title}, `)}
                    />
                  ))}
                </Grid>
              </>
            ) : null}
            {recommendationOrganizationList.length ? (
              <>
                <Typography pt={1.5} pb={0.5} variant="body2" color={colors.dark[550]}>
                  {intl.formatMessage(messages.recommendedOrganizationTitle)}
                </Typography>
                <Grid container spacing={1} justifyContent={'flex-start'} pt={1} pb={2}>
                  {recommendationOrganizationList?.map(({ name, uuid, avatarUrl, matchingPercentage, matchedKeywords }) => (
                    <AvatarNameWithButton
                      key={uuid}
                      avatarUrl={avatarUrl}
                      id={uuid}
                      name={name}
                      label={Number(matchingPercentage).toFixed(2)}
                      buttonIcon={<IconCirclePlus />}
                      iconColor={colors.default}
                      onClick={handleAddRecommendedOrganization}
                      handleTitleClick={() => handleTitleClick(uuid, true)}
                      toolTipTitle={matchedKeywords.map((title) => `${title}, `)}
                    />
                  ))}
                </Grid>
              </>
            ) : null}
          </>
        )}
      </Stack>
    </StyledGreyContainer>
  );
};

InviteOrAddNetwork.propTypes = {
  initiativeId: PropTypes.string,
  multipleInviteeList: PropTypes.array,
  setMultipleInviteeList: PropTypes.func,
  userId: PropTypes.string,
  administrators: PropTypes.arrayOf(PropTypes.object),
  administratorsList: PropTypes.array,
  setInviteesList: PropTypes.func,
  members: PropTypes.array,
  hideBorder: PropTypes.bool,
  onClick: PropTypes.func,
  showRecommendation: PropTypes.bool,
  recommendationUsersList: PropTypes.array,
  recommendationOrganizationList: PropTypes.array
};

export default memo(InviteOrAddNetwork);
