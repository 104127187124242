const queryTags = {
  userInfo: 'userInfo',
  organizationInfo: 'organizationInfo',
  AllOrganizationUser: 'AllOrganizationUser',
  AddOrganizationUser: 'AddOrganizationUser',
  updateOrganizationUser: 'updateOrganizationUser',
  initiativeById: 'initiativeById',
  allInitiatives: 'allInitiatives',
  allUsersInformation: 'allUsersInformation',
  licenseInfo: 'licenseInfo',
  initiativesUsers: 'initiativesUsers',
  initiativesReactions: 'initiativesReactions',
  searchUser: 'searchUser',
  initiativeMembers: 'initiativeMembers',
  initiativeAdministrators: 'initiativeAdministrators',
  initiativeOwner: 'initiativeOwner',
  addInvitee: 'addInvitee',
  getInitiativeInvitee: 'getInitiativeInvitee',
  keywords: 'keywords',
  getAllInitiativeComments: 'getAllInitiativeComments',
  getAllNews: 'getAllNews',
  getNewsById: 'getNewsById',
  getAllNewsComments: 'getAllNewsComments',
  getOrganizationInfo: 'getOrganizationInfo',
  usersKeywords: 'usersKeywords',
  organizationKeywords: 'organizationKeywords',
  networkList: 'networkList',
  networkDetailsByUser: 'networkDetailsByUser',
  allInitiativesByUser: 'allInitiativesByUser',
  globalSearch: 'globalSearch',
  allUser: 'allUser',
  chatByUser: 'chatByUser',
  recentChatUserList: 'recentChatUserList',
  messagesCount: 'messagesCount',
  recommendedKeywords: 'recommendedKeywords',
  initiativeActivityLogs: 'initiativeActivityLogs',
  networkActivityLogs: 'networkActivityLogs',
  initiativesTabsCount: 'initiativesTabsCount',
  getMessagesById: 'getMessagesById',
  getAllProjectExperienceByUser: 'getAllProjectExperienceByUser',
  initiativeReactions: 'initiativeReactions',
  getProfileVisibilityStatus: 'getProfileVisibilityStatus',
  getAllLinkedinInitiative: 'getAllLinkedinInitiative',
  getAllRelatedinInitiative: 'getAllRelatedinInitiative',
  myInitiative: 'myInitiative',
  getRecommendedNetwork: 'getRecommendedNetwork',
  getOrganizationsList: 'getOrganizationsList'
};

export default queryTags;
